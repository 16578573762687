// @flow
import './index.less';

import {yupResolver} from '@hookform/resolvers/yup';

import {Button, Form, Input, message, Select} from 'antd';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import React, {useCallback, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';

import {owClient} from '_client';
import FlexBlock from '_components/FlexBlock';
import Section from '_components/Section';
import {useAuthState} from '_contexts/auth';

const validationSchema = yup.object().shape({
  subjectId: yup.mixed().oneOf(['general', 'tech-support']),
  email: yup.string().email().required(),
  message: yup.string().required(),
});

const ContactUsSection = (): React$Node => {
  const {t} = useTranslation();

  const {loading: loadingUser, currentUser} = useAuthState();

  const [submitting, setSubmitting] = useState(false);

  const subjectOptions = useMemo(
    () => [
      {
        name: t('ContactUsSection_SubjectGeneral'),
        id: 'general',
        inqueryType: 2,
      },
      {
        name: t('ContactUsSection_SubjectTechSupport'),
        id: 'tech-support',
        inqueryType: 2,
      },
      {
        name: t('ContactUsSection_SubjectRequestAccountDeletion'),
        id: 'request-account-deletion',
        inqueryType: 3,
      },
    ],
    [t],
  );

  const {handleSubmit, control, errors, formState, reset} = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    values => {
      (async () => {
        const subject = subjectOptions.find(opt => opt.id === values.subjectId);
        if (!subject) {
          return;
        }

        setSubmitting(true);

        try {
          const params = {
            InquiryType: subject.inqueryType,
            Subject: `[Ritual FIT] ${subject.name}`,
            Sender: values.email,
            Message: values.message,
            CopyToSender: false,
          };

          await owClient.post('/api/v1/public/sendinquiry', params);
          reset();
        } catch (err) {
          message.error('Failed to submit.');
        } finally {
          setSubmitting(false);
        }
      })();
    },
    [subjectOptions, reset],
  );

  const submitDisabled = useMemo(() => {
    const {isDirty, isValid} = formState;
    return loadingUser || submitting || !isDirty || !isValid;
  }, [loadingUser, submitting, formState]);

  return (
    <Section>
      <FlexBlock className="contact-us-container" vertical align="center">
        <form className="contact-us-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="subjectId"
            control={control}
            defaultValue="general"
            render={({onChange, value}) => (
              <Form.Item
                className="form-item"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                label={t('Label_Topic')}
                help={errors.subjectId?.message}
                validateStatus={errors.subjectId && 'error'}>
                <Select
                  className="rf-select"
                  dropdownClassName="rf-select-dropdown"
                  onChange={onChange}
                  value={value}
                  disabled={submitting}>
                  {subjectOptions.map((opt, idx) => (
                    <Select.Option key={`subject-option-${idx}`} value={opt.id}>
                      {opt.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue={currentUser?.email}
            render={({onChange, value}) => (
              <Form.Item
                className="form-item"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                label={`${t('Label_YourEmailAddress')}*`}
                help={errors.email?.message}
                validateStatus={errors.email && 'error'}>
                <Input
                  className="rf-input"
                  name="email"
                  value={value}
                  disabled={loadingUser || submitting}
                  onChange={e => onChange(e.target.value)}
                />
                <div className="form-item-footnote">{t('ContactUsSection_FootnoteEmail')}</div>
              </Form.Item>
            )}
          />

          <Controller
            name="message"
            control={control}
            render={({onChange, value}) => (
              <Form.Item
                className="form-item"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                label={t('Label_HowCanWeHelp')}
                help={errors.message?.message}
                validateStatus={errors.message && 'error'}>
                <Input.TextArea
                  className="rf-input"
                  name="message"
                  value={value}
                  disabled={submitting}
                  onChange={e => onChange(e.target.value)}
                  autoSize={{minRows: 6}}
                />
                <div className="form-item-footnote">{t('ContactUsSection_FootnoteMessage')}</div>
              </Form.Item>
            )}
          />

          <Button
            className="submit-btn"
            type="primary"
            htmlType="submit"
            loading={loadingUser}
            block
            disabled={submitDisabled}>
            {t('Common_Submit')}
          </Button>
        </form>
      </FlexBlock>
    </Section>
  );
};

export default ContactUsSection;
